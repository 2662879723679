import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECTID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDERID,
    appId: process.env.GATSBY_FIREBASE_APPID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENTID
};

const isBrowser = typeof window !== "undefined"

let firebaseApp;
let db;
let analytics;

if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig);
} 
db = getFirestore(firebaseApp);


if (isBrowser) {
    import('firebase/analytics').then(module => {
        module.getAnalytics(firebaseApp)
    });
}

export {
    db,
    analytics
};
