import React from "react";
import { useInView } from 'react-intersection-observer';

const SliderItem = ({item, index, onClick, onViewChangeCallback}) => {
    const { ref, inView, } = useInView({
        /* Optional options */
        threshold: 0.5,
    });

    React.useEffect(() => {
        onViewChangeCallback(index, inView);
    }, [inView])
    

    return (
        <div
            ref={ref}
            className="w-64 max-w-4xl min-w-64 cursor-pointer hover:shadow-lg" 
            onClick={() => onClick(index)}
            onKeyDown={() => onClick(index)}
        >
            {
                item
            }
        </div>
    );
};

export default SliderItem;
