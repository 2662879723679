import React from "react";
import { motion } from 'framer-motion';
import { Helmet } from "react-helmet";
import Video from '../components/Video';
import About from "../components/About";
import Screenings from "../components/Screenings/Screenings";
import Gallery from "../components/Gallery/Gallery";
import BeginIcon from "../components/Icons/BeginIcon";
import NewsletterSignupForm from "../components/NewsletterSignupForm";
import Footer from "../components/Footer";

export default function Home() {
    const contentRef = React.useRef(null);

    const variants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    };

    const onClickBegin = () => {
        if (!contentRef.current) return;
        contentRef.current.scrollIntoView({behavior: "smooth"});
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>The Hidden War</title>
                <meta name="description" content="Website for the film, The Hidden War. A love story set in the time of war." />
                <link rel="canonical" href="http://thehiddenwarfilm.com" />
            </Helmet>
            <div className="relative">
                <Video/>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{duration: 1, ease: 'easeIn'}}
                >
                    <div className="flex flex-col md:space-y-4 absolute" style={{"top": "50%", "left": "35%"}}>
                        <h1 className="text-white font-medium text-2xl md:text-4xl lg:text-7xl uppercase">
                            The Hidden War
                        </h1>
                        <p className="text-white text-sm md:font-medium lg:text-xl text-right">
                            a film by Ajay Brar
                        </p>
                    </div>
                    <div className="absolute" style={{"bottom": "10%", "left": "48%"}}>
                        <motion.button 
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={onClickBegin}
                        >
                            <BeginIcon />
                        </motion.button>
                    </div>
                </motion.div>
            </div>
            <div className="relative z-10 flex flex-col" ref={contentRef}>
                <About />
                <Screenings />
                <div>
                    <Gallery />
                </div>
                <NewsletterSignupForm />
                <Footer />
            </div>
        </>
    );
}
