import React from "react";
import Section from "../Section";
import ScreeningIcon from "./ScreeningIcon";
import ScreeningEvent from './ScreeningEvent';
import signs from '../../media/signs-logo.png';

const Screenings = () => {
    return (
        <Section title="Next Screening" icon={ScreeningIcon}>
            <ScreeningEvent date="April 2022" image={signs} sessions={['Tuesday, 05 Apr, 11:15 IST']} url="https://www.signsfestival.in/schedule/#tab-id-4-active" />
        </Section>
    );
};

export default Screenings;
