import React from "react";

const Footer = (props) => {
    return (
        <div className="flex py-4 px-8 bg-gray-800 justify-start">
            <p className="text-gray-100">© Maati Pictures 2021</p>
        </div>
    );
};

export default Footer;
