import React from "react";

const SliderContent = ({translate, transition, children}) => {

    return (
        <div
            className="flex space-x-8"
            style={{
                transform: `translateX(-${translate}px)`,
                transition: `transform ease-out ${transition}s`
            }}
        >
        {
            children
        } 
        </div>
    );
};

export default SliderContent;
