import React from "react";
import { collection, addDoc } from "firebase/firestore";
import TextField from "./Textfield";
import Button from "./Button";
import { db } from '../integrations/firebase';

const NewsletterSignupForm = () => {
    const [email, setEmail] = React.useState('');
    const [name, setName] = React.useState('');
    const [status, setStatus] = React.useState(null);

    const signUp = async () => {
        if (!name || !email) return;
        try {
            await addDoc(collection(db, "newsletter"), {
                name: name,
                email: email,
                createdAt: new Date()
            });
            setStatus('Thanks for signing up. We will email you with updates about the film.');
        } catch(e) {
            console.log(e);
            setStatus('There was an error signing up. Please try later.');
        };
    }
    
    return (
        <div className="flex flex-col items-center p-8 space-y-4">
            <p className="text-xl text-gray-700">Sign up to receive updates</p>
            {
                status && <p className="text-gray-700">{status}</p>
            }
            <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-8 p-2">
                <TextField fieldType="text" placeholder="Name" content={name} onChangeCallback={setName}/>
                <TextField fieldType="email" placeholder="Email Address" content={email} width="w-96" onChangeCallback={setEmail}/>
                <Button title="Submit" onClick={signUp} isEnabled={true} />
            </div>
        </div>
    );
};

export default NewsletterSignupForm;
