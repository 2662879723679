import React from "react";

const TextField = ({
    fieldType, placeholder, content, style, width="", onChangeCallback, onFocusCallback, onBlurCallback
}) => {

    function handleChange(e) {
        onChangeCallback(e.target.value);
    }

    return (
        <div className={width}>
            <input 
                type={fieldType}
                className={`border border-neutral-400 rounded w-full focus:outline-none focus:shadow-outline px-3 py-2`}
                value={content}
                placeholder={placeholder} 
                onChange={handleChange} 
                onBlur={onBlurCallback}
                onFocus={onFocusCallback}
                style={style}
            />
        </div>
    );
};

export default TextField;