import React from "react";

const BeginIcon = () => {
    return (
        <svg viewBox="0 0 48 48" className="w-8 h-8 md:w-12 md:h-12">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M24,0 C10.7706262,0 2.27373675e-13,10.7734171 2.27373675e-13,24 C2.27373675e-13,37.2293738 10.7734171,48 24,48 C37.2293738,48 48,37.2265829 48,24 C48,10.7706262 37.2286761,0 24,0 Z M34.3813012,27.9630211 L25.1721612,37.1721612 C24.8941636,37.5073923 24.4471655,37.6736438 24.0002093,37.6736438 C23.5532531,37.6736438 23.1635095,37.5073923 22.8282575,37.1721612 L13.6191174,27.9630211 C12.9486552,27.2925589 13.0058887,26.2323391 13.6736229,25.6191174 C14.3440851,25.0058887 15.292531,25.0058887 15.9629653,25.6191174 L22.3268934,32.0375371 L22.3268934,11.9426478 C22.3268934,10.9941741 23.0518891,10.2692482 24.000293,10.2692482 C24.948697,10.2692482 25.6736927,10.9942439 25.6736927,11.9426478 L25.6736927,32.0375371 L32.0948334,25.6736089 C32.7652957,25.0031467 33.8255154,25.0603803 34.4387371,25.7281144 C34.9974533,26.3440642 34.9974533,27.3497436 34.3815036,27.9629653 L34.3813012,27.9630211 Z" id="Fill-1" fill="#FFFFFF"></path>
            </g>
        </svg>
    );
};

export default BeginIcon;
