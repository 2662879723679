import React from "react";
import Button from "../Button";

const ScreeningEvent = ({date, image, sessions, url}) => {
    return (
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-12 lg:space-x-48 md:items-center">
            <div className="flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-12 lg:space-x-20 md:items-center justify-between">
                <img src={image} alt="Event" className="w-48 lg:w-52"/>
                <div className="flex flex-col space-y-2">
                {
                    sessions && sessions.map((s, i) => 
                        <div key={i} className="flex items-center space-x-4">
                            <div className="w-2 h-2 rounded-xl bg-black" />
                            <p className="lg:text-lg">{s}</p>
                        </div>
                    )
                }
                </div>
            </div>
            <Button title="View Details" buttonType="outlined" link={true} url={url} isEnabled={true} />
        </div>
    );
};

export default ScreeningEvent;
