import React from "react";

const Section = ({ title, icon, children }) => {
    const Icon = icon;
    return (
        <div className="flex flex-col space-y-6 lg:space-y-12 p-6 lg:px-48 lg:py-16">
            <div className="flex items-center space-x-2">
                <Icon />
                <h1 className="text-2xl lg:text-4xl">{title}</h1>
            </div>
            <div>
            {
                children
            }
            </div>
        </div>
    );
};

export default Section;
