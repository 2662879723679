import React from "react";
import LeftArrow from "./LeftArrow";
import RightArrow from './RightArrow';

const SliderControl = ({direction, top=40, handleClick}) => {
    const d = direction === 'right' ? {right: '25px'} : {left: '25px'};

    return (
        <button
            className="absolute cursor-pointer"
            onClick={handleClick}
            onKeyDown={handleClick}
            style={{
                top: `${top}%`,
                transform: `translateX(${direction === 'left' ? '-2' : '2'}px)`,
                ...d
            }}
        >
        {
            direction === 'right' ? <RightArrow /> : <LeftArrow />
        } 
        </button>
    );
};

export default SliderControl;
