import React from "react";

const GalleryIcon = () => {
    return (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-341.000000, -1701.000000)" fill="#000000">
                <path d="M354.5,1713 L353,1711.5 L350,1714.5 L362,1714.5 L357.5,1710 L354.5,1713 Z M360.5,1722 C360.5,1723.65655 359.156552,1725 357.5,1725 L344,1725 C342.343448,1725 341,1723.65655 341,1722 L341,1708.5 C341,1706.84345 342.343448,1705.5 344,1705.5 L344,1722 L360.5,1722 Z M348.5,1701 L362,1701 C363.656552,1701 365,1702.34345 365,1704 L365,1717.5 C365,1719.15655 363.656552,1720.5 362,1720.5 L348.5,1720.5 C346.843448,1720.5 345.5,1719.15655 345.5,1717.5 L345.5,1704 C345.5,1702.34345 346.843448,1701 348.5,1701 Z M351.5,1710 C352.328744,1710 353,1709.32874 353,1708.5 C353,1707.67126 352.328744,1707 351.5,1707 C350.671256,1707 350,1707.67126 350,1708.5 C350,1709.32874 350.671256,1710 351.5,1710 Z"></path>
            </g>
        </g>
    </svg>
    );
};

export default GalleryIcon;
