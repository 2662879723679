import React from "react";
import CoverPlaceholder from '../media/cover-image.jpg';
import cover from "../media/cover-video.mp4";

const Video = () => {
    const videoRef = React.useRef();
    
    return (
    <div className="w-full h-full">
        <video
            ref={videoRef}
            autoPlay
            loop
            poster={CoverPlaceholder}
            src={cover}
            playsInline
            muted
        >
            
        </video>
    </div>
    );
};

export default Video;
