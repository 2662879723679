import React from "react";
import { navigate } from "gatsby-link";

const Button = ({title, buttonType, link, url, onClick, isEnabled}) => {
    const onClickButton = () => {
        if (isEnabled) {
            if (link) {
                navigate(url);
            } else {
                onClick();
            }
        }
    }

    return (
        <button type="button"
                className="px-4 py-2 border border-primary-700 rounded bg-gray-900 text-white uppercase text-sm"
                value={title}
                onClick={onClickButton}>
            <span>{title}</span>
        </button>
    );
};

export default Button;
