import React from "react";

const Tab = ({position, text, selected, onClick}) => {
    const color = selected ? 'bg-red-700 text-white hover:bg-red-900 hover:text-gray-100' : 'bg-white text-gray-900 hover:bg-red-700 hover:text-white';
    return (
        <button
            className={`p-2 cursor-pointer border-r border-gray-200 ${position === 'start' && 'rounded-l-2xl'} ${position === 'end' && 'rounded-r-2xl'} ${color} uppercase`}
            onClick={() => onClick(text)}
            onKeyDown={() => onClick(text)}
        >
            { text }
        </button>
    )
}

const TabControl = ({tabs, selectedTab, onSelect}) => {
    
    return (
        <div className="flex uppercase shadow-lg rounded-l-2xl rounded-r-2xl">
        {
            tabs && tabs.map((t, i) => 
                <Tab 
                    key={i} 
                    position={i === 0 ? 'start' : (i === tabs.length - 1 ? 'end' : '') } 
                    text={t} onClick={onSelect}
                    selected={t === selectedTab}
                />
            )
        }
        </div>   
    );
};

export default TabControl;
