import React from 'react';

const CloseIcon = () => {
    return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" className="fill-current">
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <g transform="translate(-667.000000, -1599.000000)">
                <path d="M688.886041,1623.00291 C688.32048,1623.00436 687.776693,1622.77755 687.379776,1622.37481 L678.999274,1614.01776 L670.636266,1622.37481 C669.804617,1623.20646 668.455385,1623.20646 667.623736,1622.37481 C666.792088,1621.54316 666.792088,1620.19393 667.623736,1619.36228 L675.98079,1610.99927 L667.623736,1602.63627 C666.792088,1601.80462 666.792088,1600.45538 667.623736,1599.62374 C668.455385,1598.79209 669.804617,1598.79209 670.636266,1599.62374 L678.999274,1607.98079 L687.362283,1599.62374 C688.193931,1598.79209 689.543164,1598.79209 690.374812,1599.62374 C691.20646,1600.45538 691.20646,1601.80462 690.374812,1602.63627 L682.017759,1610.99927 L690.374812,1619.36228 C690.774632,1619.7621 691,1620.30295 691,1620.86855 C691,1621.43415 690.774643,1621.97499 690.374812,1622.37481 C689.980799,1622.77318 689.445759,1623 688.886004,1623.00291 L688.886041,1623.00291 Z"></path>
            </g>
        </g>
    </svg>
    );
};

export default CloseIcon;
