import React from "react";
import poster from '../media/poster.jpg';

const About = () => {
    return (
        <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 lg:space-x-12 items-center bg-black p-6 lg:px-48 lg:py-16">
            <img src={poster} className="w-100" alt="Film Poster" />
            <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-24 items-start">
                <div className="flex flex-col space-y-4 lg:w-128">
                    <p className="text-white italic font-light font-serif text-xl">Love in the time of war</p>
                    <p className="text-gray-300">
                        Set against the backdrop of one of the world’s longest running armed conflicts – India’s Maoist rebellion – the film follows the intersecting journeys of one displaced couple and one former rebel, as they search for home, justice, and sometimes for revenge.
                    </p>
                </div>
                <div className="flex flex-col space-y-4">
                    <div className="flex flex-col space-y-1">
                        <p className="text-white font-semibold text-sm">Produced & Directed by</p>
                        <p className="text-gray-300 text-sm">Ajay Brar</p>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <p className="text-white font-semibold text-sm">Cinematography</p>
                        <p className="text-gray-300 text-sm">Amith Surendran</p>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <p className="text-white font-semibold text-sm">Editing</p>
                        <p className="text-gray-300 text-sm">Manas Mittal</p>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <p className="text-white font-semibold text-sm">Sound</p>
                        <p className="text-gray-300 text-sm">Bigyna Dahal</p>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <p className="text-white font-semibold text-sm">Music</p>
                        <p className="text-gray-300 text-sm">Debajyoti Misra</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
