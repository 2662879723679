import React from "react";
import ModalContainer from "../ModalContainer";
import SliderControl from "./SliderControl";

const SliderItemDetail = ({item, prevCallback, nextCallback, closeCallback}) => {
    return (
        <ModalContainer top={5} bottom={5} left={10} right={10} closeCallback={closeCallback}>
            <div className="w-full h-full">
                <div className="flex w-full h-full">
                    {
                        item
                    }
                </div>
                <SliderControl direction="left" top={45} handleClick={prevCallback} />
                <SliderControl direction="right" top={45} handleClick={nextCallback} />
            </div>
        </ModalContainer>
    );
};

export default SliderItemDetail;
