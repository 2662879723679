import React from "react";
import { StaticImage } from 'gatsby-plugin-image';
import SectionFull from "../SectionFull";
import Slider from "../Slider/Slider";
import GalleryIcon from "./GalleryIcon";
import TabControl from "../TabControl";

const bastarImages = [
    <StaticImage src="../../media/gallery/bastar/_MG_9891_13629.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/_MG_9893_13633.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/_MG_9901_13102.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/_MG_9930_13160.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/IMG_6274.JPG" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/IMG_6289.JPG" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/IMG_6306.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/IMG_6307.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/IMG_6310.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/IMG_6313.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/IMG_6325.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/IMG_6462.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/IMG_6481.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/IMG_6484.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/IMG_6488.jpg" alt="Bastar Images" />,
    <StaticImage src="../../media/gallery/bastar/IMG_6492.jpg" alt="Bastar Images" />,
];

const productionImages = [
    <StaticImage src="../../media/gallery/production/_MG_9917_13134.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_0016.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_0023.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_0040.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_0054.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_6367.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_6423.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_6426.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_6465.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_6495.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_6498.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_6505.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_6547.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_6549.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_9931_13709.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_9933.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_9935.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_9938.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_9940.jpg" alt="production" />,
    <StaticImage src="../../media/gallery/production/IMG_9973.jpg" alt="production" />,
];

const stillsImages = [
    <StaticImage src="../../media/gallery/stills/beeme_lakma_after_fight_night.png" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/beeme_talking_neighbour.png" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/beeme_waiting_night_lakma.png" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/Beeme_watching_Lakma_teach_hindi.png" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/cnm_woman_fist_raised.png" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/durga_logs_twilight.png" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/gompad_woman_crying.png" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/konjed_crpf_forest_1.png" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/lakma_bus_travel.png" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/mahua_forest_dawn.png" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/Policeman_Konta_market.png" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/The Hidden War - Still 1.jpg" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/The Hidden War - Still 2.jpg" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/The Hidden War - Still 3.jpg" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/The Hidden War - Still 4-old.jpg" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/The Hidden War - Still 4.jpg" alt="Film Still" />,
    <StaticImage src="../../media/gallery/stills/troops_firing_training.png" alt="Film Still" />,
];

const tabs = ['Bastar', 'Production', 'Film Stills'];

const getImages = (tab) => {
    switch(tab) {
    case 'Bastar':
        return bastarImages;
    case 'Production':
        return productionImages;
    case 'Film Stills':
    default:
        return stillsImages;
    }
}

const Gallery = () => {
    const [selectedTab, setSelectedTab] = React.useState('Bastar');

    return (
        <div className="bg-gray-300">
        <SectionFull title="Photo Gallery" icon={GalleryIcon}>
            <div className="flex justify-center"> 
                <TabControl tabs={tabs} selectedTab={selectedTab} onSelect={setSelectedTab} />
            </div>
            <Slider slides={getImages(selectedTab)} />    
        </SectionFull>
        </div>
    );
};

export default Gallery;
