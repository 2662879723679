import React from "react";
import Portal from "./Portal";
import CloseIcon from "./Icons/CloseIcon";

const ModalContainer = ({ top, left, right, bottom=10, children, closeCallback }) => {
    const ref = React.useRef();

    const handleEscape = (event) => {
        if (event.key === 'Escape') {
            closeCallback();
        }
    };

    const handleClickOutside = (event) => {
        if (ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
            event.preventDefault();
            event.stopPropagation();
            closeCallback();
        }
    };

    React.useEffect(() => {
        document.addEventListener('keydown', handleEscape, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleEscape, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });
    return (
        <Portal>
            <div className="bg-gray-700 fixed w-full h-full m-auto bg-opacity-50 z-30" style={{top: "0px", left: "0px"}}>
                <div
                    className="bg-white flex flex-col absolute rounded shadow-md"
                    style={{top: `${top || 30}%`, bottom: `${bottom}%`, left: `${left || 30}%`, right: `${right || 30}%`}}
                    ref={ref}
                >
                        <button 
                            className="cursor-pointer bg-gray-100 opacity-50 p-2 z-10 text-black absolute rounded-3xl hover:opacity-90 hover:text-red-700" 
                            style={{right: '16px', top: '16px'}}
                            onClick={closeCallback}
                            onKeyDown={closeCallback}
                        >
                            <CloseIcon />
                        </button>
                        {children}
                    
                </div>
            </div>
        </Portal>
    )
};

export default ModalContainer;