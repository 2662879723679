import React from "react";

const LeftArrow = () => {
    return (
        <div className="bg-gray-100 opacity-50 text-black hover:text-red-700 hover:opacity-90">
            <svg width="48px" height="48px" viewBox="0 0 48 48">
                <g stroke="none" strokeWidth="1" fillRule="evenodd">
                    <g transform="translate(-69.000000, -1937.000000)">
                        <polygon points="91.0254714 1961.47624 103 1973.95986 98.7958988 1977.99404 83 1961.47657 98.7958988 1945 103 1949.03418"></polygon>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default LeftArrow;
