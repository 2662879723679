import React from "react";
import SliderContent from "./SliderContent";
import SliderItem from "./SliderItem";
import SliderControl from "./SliderControl";
import SliderItemDetail from "./SliderItemDetail";

const Slider = ({slides}) => {
    const [state, setState] = React.useState({
        activeIndex: 0,
        translate: 0
    });
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [inviewItems, setInviewItems] = React.useState(slides.map(s => false));
    const [maxItems, setMaxItems] = React.useState(5);

    React.useEffect(() => {
        if (window.innerWidth < 480) {
            setMaxItems(1);
        } else if (window.innerWidth < 680) {
            setMaxItems(3);
        }
    }, []);

    React.useEffect(() => {
        console.log(slides);
        setState({
            activeIndex: 0,
            translate: 0
        });
        setSelectedIndex(null);
        setInviewItems(slides.map(s => false));
    }, [slides])
    
    const { translate, activeIndex } = state;

    const nextSlide = () => {
        const maxIndex = inviewItems.findIndex(i => i === true);
        if (maxIndex === slides.length - maxItems) {
            return setState({
                translate: 0,
                activeIndex: 0
            })
        }
        setState({
            activeIndex: activeIndex + 1,
            translate: (activeIndex + 1) * 256
        })
      }
    
    const prevSlide = () => {
        const minIndex = inviewItems.findIndex(i => i === true);
        if (minIndex === 0) {
            return setState({
                translate: (slides.length + 1 - maxItems) * 256,
                activeIndex: slides.length - maxItems
            })
        }
        setState({
            activeIndex: activeIndex - 1,
            translate: (activeIndex - 1) * 256
        })
      }
    
    const prevSelection = () => {
        setSelectedIndex(selectedIndex === 0 ? slides.length - 1 : selectedIndex - 1);
    }

    const nextSelection = () => {
        setSelectedIndex(selectedIndex === (slides.length - 1) ? 0 : (selectedIndex + 1));
    }

    const onViewChange = (index, inView) => {
        setInviewItems(items => {
            const itemsCopy = [...items];
            itemsCopy[index] = inView;
            return itemsCopy;
        });
    }

    return (
        <div className="relative overflow-hidden">
            <SliderContent
                translate={translate}
                transition={0.45}
            >
                {
                    slides && slides.map((slide, index) => 
                        <SliderItem key={slide + index} item={slide} index={index} onClick={setSelectedIndex} onViewChangeCallback={onViewChange}  />
                    )
                }
            </SliderContent>
            <SliderControl direction="left" handleClick={prevSlide} />
            <SliderControl direction="right" handleClick={nextSlide} />
            {
                selectedIndex !== null && 
                    <SliderItemDetail 
                        item={slides[selectedIndex]} 
                        prevCallback={prevSelection} 
                        nextCallback={nextSelection}
                        closeCallback={() => setSelectedIndex(null)}
                    />
            }
        </div>
    );
};

export default Slider;
